<template>
  <div
    :class="{ disabled: disabled }"
    class="resource_editor"
  >
    <div class="resource card">
      <b-overlay
        :show="loading"
        :variant="'white'"
        :opacity="0.9"
        :blur="'2px'"
        style="z-index: 1040;"
      >
        <component
          :is="resourceComponent"
          v-if="resourceComponent"
          :ref="isMain ? 'resourceComponent' : 'annexComponent'"
          :resource="resource"
          :meta-resource="metaResource"
          :is-main="isMain"
          v-on="$listeners"
        >
          <template v-slot:publication>
            <PublicationCheckbox
              :is-main="isMain"
              :meta-resource="metaResource"
              @loading="setLoading"
            />
          </template>
        </component>
        <div
          v-else
          class="card-header"
        >
          <div class="header-top">
            <div class="resource_icon">
              <i class="bi bi-question-circle-fill" />
            </div>
            <div class="resource_name">
              {{ $t('datasetDetail.mainResource.empty') }}
            </div>
          </div>
        </div>
        <div class="card-body">
          <ResourceAccordion
            :id="metaResource.id"
            :is-main="isMain"
            :form-config="$config.forms.resource.geoserver"
            :meta-resource="metaResource"
            v-on="$listeners"
          />
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import DatastoreEditor from '@/components/Resource/editors/DatastoreEditor.vue';
import FileDownloadEditor from '@/components/Resource/editors/FileDownloadEditor.vue';
import FileUploadEditor from '@/components/Resource/editors/FileUploadEditor.vue';
import FTPEditor from '@/components/Resource/editors/FTPEditor.vue';
import GeoserverEditor from '@/components/Resource/editors/GeoserverEditor.vue';
import HrefEditor from '@/components/Resource/editors/HrefEditor.vue';

import PublicationCheckbox from '@/components/Resource/PublicationCheckbox.vue';
import ResourceAccordion from '@/components/Resource/ResourceAccordion.vue';

export default {
  name: 'ResourceEditor',

  components: {
    PublicationCheckbox,
    ResourceAccordion
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isMain: {
      type: Boolean,
      default: true
    },
    resource: {
      type: Object,
      default: () => {}
    },
    metaResource: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      loading: false
    };
  },

  computed: {
    resourceComponent() {
      if (this.resource) {
        switch (this.resource['@class']) {
        case 'postgres':
        case 'postgis':
          return DatastoreEditor;
        case 'fileupload':
          return FileUploadEditor;
        case 'ftp':
          return FTPEditor;
        case 'href':
          return HrefEditor;
        case 'filedownload':
          return FileDownloadEditor;
        case 'geographiclayer':
          return GeoserverEditor;
        default:
          return null;
        }
      }
      return null;
    }
  },

  methods: {
    setLoading(e) {
      this.loading = e;
    },
  }
};
</script>
