<template>
  <div class="toggle-button">
    <label class="switch">
      <input
        v-model="state"
        type="checkbox"
        @change="toggle"
      >
      <span class="slider round" />
    </label>
    <label>
      {{ state ? active.label : disabled.label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'SwitchButton',

  props: {
    active: {
      type: Object,
      default: () => {
        return {
          label: 'Activé',
          value: 1
        };
      }
    },
    disabled: {
      type: Object,
      default: () => {
        return {
          label: 'Désactivé',
          value: 0
        };
      }
    },
    initialValue: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      state: false
    };
  },

  watch: {
    initialValue(newValue) {
      this.state = newValue;
    }
  },

  created() {
    this.state = this.initialValue;
  },

  methods: {
    toggle() {
      if (this.state) {
        this.$emit('toggle', this.active.value);
      } else {
        this.$emit('toggle', this.disabled.value);
      }
    }
  }
};
</script>

<style scoped lang="less">
.toggle-button {
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  height: 1.2rem;
  width: fit-content;
  margin: 1rem 0;
}
.switch {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 1.2rem;
  margin-right: 0.75rem;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  left: 0.15rem;
  top: 0.17rem;
  content: "";
  height: 0.85rem;
  width: 0.85rem;
  background-color: white;
  box-shadow: 0 0 0px 1px #b7b7b7;
  -webkit-transition: .4s;
  transition: .4s;
}

input:indeterminate + .slider {
  background-color: @blue50;
}

input:checked + .slider {
  background-color: @blue;
}

input:focus + .slider {
  box-shadow: 0 0 1px @blue;
}

input:indeterminate + .slider:before {
  -webkit-transform: translateX(0.68rem);
  -ms-transform: translateX(0.68rem);
  transform: translateX(0.68rem);
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.30rem);
  -ms-transform: translateX(1.30rem);
  transform: translateX(1.30rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>