<template>
  <b-modal
    id="parent-dataset-modal"
    v-model="openModal"
    size="xl"
    :title="$t('modals.parentDataset.title')"
  >
    <b-overlay
      :show="loading"
      rounded="lg"
      opacity="0.6"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner
            small
            type="grow"
            variant="secondary"
          />
          <b-spinner
            type="grow"
            variant="dark"
          />
          <b-spinner
            small
            type="grow"
            variant="secondary"
          />
          <span class="sr-only">Please wait...</span>
        </div>
      </template>
      <div class="parent-dataset-modal-content">
        <div class="filter-selection">
          <label style="margin-bottom: 0.5rem;">
            {{ $t('modals.parentDataset.help') }}
          </label>
          <Multiselect
            :options="
              datasetsRecordTypes.filter(el => selectedRecordTypes.findIndex(sel => sel.choice === el.choice) === -1)
            "
            class="recordType-selector"
            track-by="choice"
            label="label"
            select-label=""
            selected-label=""
            deselect-label=""
            :searchable="false"
            :placeholder="$t('datasetDetail.recordType')"
            @select="selectRecordTypeFilter"
          />
          <label
            v-if="selectedRecordTypes && selectedRecordTypes.length"
            style="margin-top: 0.5rem;"
          >
            Filtres actifs :
          </label>
          <div class="recordType-selection">
            <div
              v-for="recordType of selectedRecordTypes"
              :key="recordType.choice"
              class="recordType-item"
            >
              {{ recordType.label }}
              <i
                class="bi bi-x"
                @click="removeRecordTypeFilter(recordType)"
              />
            </div>
          </div>
        </div>
        <label>
          {{ $t('datasetDetail.parentDataset.title') }}
        </label>
        <SearchDatasets
          v-model="selectedParent"
          :usergroup="usergroup"
          :disabled="!usergroup"
          :placeholder="$t('datasetDetail.parentDataset.placeholder')"
          :limit="15"
          :record-types="selectedRecordTypes.map(el => el.choice)"
          @select="setParent"
          @close="setParent"
        />
      </div>
    </b-overlay>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          :class="{ disabled: !selectedParent || loading }"
          variant="primary"
          class="float-right"
          @click="selectParent"
        >
          {{ $t('modals.parentDataset.confirm') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import datasetsAPI from '@/api/datasetsAPI.js';

import Multiselect from 'vue-multiselect';
import SearchDatasets from '@/components/Searchbars/SearchDatasets.vue';

export default {
  name: 'ParentDatasetModal',

  components: {
    Multiselect,
    SearchDatasets
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },
    usergroup: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      openModal: false,
      loading: false,
      selectedParent: null,
      selectedRecordTypes: []
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentDataset',
      'datasetsRecordTypes'
    ]),
    ...mapGetters('datasets', [
      'currentDatasetPublishState',
      'currentDatasetPublishScenario',
      'currentDatasetUnpublishScenario'
    ]),

    buildDatasetIndexScenarioId() {
      if (
        this.currentDataset &&
        this.currentDatasetPublishScenario &&
        this.currentDatasetPublishScenario.id &&
        this.currentDatasetUnpublishScenario &&
        this.currentDatasetUnpublishScenario.id
      ) {
        return this.currentDatasetPublishState ?
          this.currentDatasetPublishScenario.id :
          this.currentDatasetUnpublishScenario.id;
      }
      return null;
    },
  },

  watch: {
    open(newValue) {
      this.resetModal();
      this.openModal = newValue;
    },
  },

  mounted() {
    this.selectedParent = null;
  },

  methods: {
    ...mapActions('datasets', [
      'GET_DATASET_DETAIL',
      'PATCH_DATASET',
    ]),
    resetModal() {
      this.openModal = false;
    },

    selectRecordTypeFilter(e) {
      const index = this.selectedRecordTypes.findIndex(el => el.choice === e.choice);
      if (index === -1) {
        this.selectedRecordTypes.push(e);
      } else {
        this.selectedRecordTypes.splice(index, 1);
      }
    },
    removeRecordTypeFilter(e) {
      const index = this.selectedRecordTypes.findIndex(el => el.choice === e.choice);
      this.selectedRecordTypes.splice(index, 1);
    },

    setParent(e) {
      if ((e && this.selectedParent !== e.id) || (!e && this.selectedParent)) {
        this.selectedParent = e ? e.id : null;
      }
    },
    async selectParent() {
      try {
        const data = {
          parent: this.selectedParent
        };
        this.loading = true;
        await this.PATCH_DATASET({
          id: this.currentDataset.id,
          data: data
        });
        if (this.buildDatasetIndexScenarioId) {
          await datasetsAPI.buildDatasetIndex(this.$route.params.id, this.buildDatasetIndexScenarioId);
        }
        await this.GET_DATASET_DETAIL(this.$route.params.id);
        this.loading = false;
        this.selectedParent = null;
        this.$emit('hide');
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.parent-dataset-modal-content {
  padding: 0 2rem 2rem !important;
}
.filter-selection {
  margin-bottom: 2rem;
  p.help {
    color: grey;
    font-style: italic;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
  .recordType-selector {
    width: 50%;
  }
  .recordType-selection {
    margin: 0.25rem 0 0.75rem;
    display: flex;
    .recordType-item {
      background: @blue;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      padding: 0 0.25rem 0 0.5rem;
      margin-right: 0.5rem;
      border-radius: 8px;
      font-size: 0.9rem;
      .bi-x {
        cursor: pointer;
        font-size: 1.3rem;
        margin-left: 0.5rem;
        margin-top: 0.1rem;
      }
      .bi-x:hover {
        transform: scale(1.1);
      }
    }
  }
}
.record-types-buttons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1rem;
  .btn {
    box-shadow: none !important;
  }
}
</style>