<template>
  <div class="card-header">
    <div class="header-top">
      <div class="resource_icon">
        <i class="bi bi-file-earmark-arrow-down-fill" />
      </div>
      <div class="resource_format">
        {{ resourceDataFormats.find(el => el.id === resource.dataformat.id).codename }}
      </div>
      <div class="resource_name">
        {{ resource.href }}
      </div>
      <i
        v-if="!isURLCopied"
        class="bi bi-clipboard"
        @click="copyToClipboard"
      />
      <i
        v-else
        class="bi bi-clipboard-check"
        @click="copyToClipboard"
      />
    </div>
    <div>
      <div class="form-row">
        <div class="form-group col-6">
          <label 
            class="required"
            style="margin-top: 1em;"
          >
            {{ $t('resource.format.label') }}
          </label>
          <p class="label-help">
            {{ $t('resource.format.help') }}
          </p>
          <Multiselect
            v-model="newResource.format"
            :options="allowedFormats ? allowedFormats : resourceDataFormats"
            track-by="codename"
            label="display_name"
            select-label=""
            selected-label=""
            deselect-label=""
            :searchable="false"
            :placeholder="$t('resource.format.placeholder')"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12">
          <label>
            {{ $t('resource.url.label') }}:
          </label>
          <input
            v-model="newResource.href"
            v-sanitize
            class="form-control"
            :placeholder="resource && resource.href ? resource.href : 'https://'"
          >
        </div>
      </div>
      <div class="resource-form-footer">
        <slot name="publication" />
        <b-button
          variant="outline-secondary"
          :disabled="!canUpdateResource"
          @click="modifyFileDownload"
        >
          {{ $t('buttons.update') }}
        </b-button>
      </div>
      <div
        v-if="isMain"
        class="form-row"
      >
        <div class="form-group col-6">
          <label style="margin-top: 1em;">
            {{ $t('resource.scheduler.label') }}
          </label>
          <p>
            {{ $t('resource.scheduler.help') }}
          </p>
          <b-overlay
            :show="loadingScheduler"
            rounded="lg"
            opacity="0.6"
          >
            <template #overlay>
              <div class="d-flex align-items-center">
                <b-spinner
                  small
                  type="grow"
                  variant="secondary"
                />
                <b-spinner
                  type="grow"
                  variant="dark"
                />
                <b-spinner
                  small
                  type="grow"
                  variant="secondary"
                />
                <span class="sr-only">Please wait...</span>
              </div>
            </template>
            <Multiselect
              v-model="scheduler"
              :options="schedulers"
              :disabled="!selectedScenario"
              track-by="id"
              label="label"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :placeholder="$t('resource.scheduler.placeholder')"
              @select="setScheduler"
              @remove="removeScheduler"
            />
          </b-overlay>
        </div>
        <div class="form-group col-6">
          <label style="margin-top: 1em;">
            {{ $t('resource.scenario.label') }}
          </label>
          <p>
            {{ $t('resource.scenario.help') }}
          </p>
          <b-overlay
            :show="loadingScheduler"
            rounded="lg"
            opacity="0.6"
          >
            <template #overlay>
              <div class="d-flex align-items-center">
                <b-spinner
                  small
                  type="grow"
                  variant="secondary"
                />
                <b-spinner
                  type="grow"
                  variant="dark"
                />
                <b-spinner
                  small
                  type="grow"
                  variant="secondary"
                />
                <span class="sr-only">Please wait...</span>
              </div>
            </template>
            <Multiselect
              v-model="selectedScenario"
              :options="scenarios"
              :disabled="scenarios.length < 2"
              track-by="id"
              label="label"
              select-label=""
              selected-label=""
              deselect-label=""
              :allow-empty="false"
              :searchable="false"
              :placeholder="$t('resource.scenario.placeholder')"
              @select="setScheduler"
            />
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import resourcesAPI from '@/api/resourcesAPI.js';
import fileDownloadAPI from '@/api/resource/file-downloadAPI';
import { slugify } from '@/utils';
import { ErrorService } from '@/services/error-service.js';

import Multiselect from 'vue-multiselect';

export default {
  name: 'FileDownloadEditor',

  components: {
    Multiselect,
  },

  props: {
    isMain: {
      type: Boolean,
      default: true
    },
    resource: {
      type: Object,
      default: () => {}
    },
    metaResource: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      // scheduler
      scheduler: null,
      scenarios: [],
      selectedScenario: null,
      loadingScheduler: false,
      originalScheduler: null, // to know if scheduler need to be created or updated

      isURLCopied: false,
      newResource:{
        href: null,
        format: null
      },
    };
  },

  computed: {
    ...mapState('resources', [
      'resourceDataFormats',
      'schedulers',
    ]),
    ...mapState('datasets', [
      'currentDataset'
    ]),

    allowedFormats() {
      if (this.resource.dataformat && this.resource.dataformat.id) {
        const format = this.resourceDataFormats.find(el => el.id === this.resource.dataformat.id);
        const type = this.resourceDataFormats.find(el => el.codename === format.codename).type;
        if (type && type.id) {
          return this.resourceDataFormats.filter(el => el.type.id === type.id);
        }
      }
      return null;
    },

    canUpdateResource() {
      return this.newResource.format && this.newResource.href;
    }
  },

  watch: {
    resource: {
      deep: true,
      async handler(newValue) {
        if (newValue['scenario-execution-frequency']) {
          const response = await resourcesAPI.getResourceExecutionFrequency(newValue['scenario-execution-frequency']);
          if (response && response.results && response.results.length) {
            this.scheduler = response.results[0].scheduler;
            this.originalScheduler = response.results[0];
            if (newValue._scenarios && newValue._scenarios.update && newValue._scenarios.update.length) {
              this.scenarios = newValue._scenarios.update;
              if (this.scenarios.length) {
                if (
                  this.originalScheduler &&
                  this.originalScheduler.core_scenario &&
                  this.scenarios.find(el => el.id === this.originalScheduler.core_scenario.codename)
                ) {
                  this.selectedScenario = this.scenarios.find(el => el.id === this.originalScheduler.core_scenario.codename);
                } else {
                  this.selectedScenario = this.scenarios[0];
                }
              }
            }
          }
        } else {
          this.originalScheduler = null;
        }
      }
    }
  },

  async created() {
    if (this.schedulers.length === 0) {
      await this.GET_SCHEDULERS();
    }
    if (this.resource['scenario-execution-frequency']) {
      const response = await resourcesAPI.getResourceExecutionFrequency(this.resource['scenario-execution-frequency']);
      if (response && response.results && response.results.length) {
        this.scheduler = response.results[0].scheduler;
        this.originalScheduler = response.results[0];
      }
    }
    if (this.resource._scenarios && this.resource._scenarios.update && this.resource._scenarios.update.length) {
      this.scenarios = this.resource._scenarios.update;
      if (this.scenarios.length) {
        if (
          this.originalScheduler &&
          this.originalScheduler.core_scenario &&
          this.scenarios.find(el => el.id === this.originalScheduler.core_scenario.codename)
        ) {
          this.selectedScenario = this.scenarios.find(el => el.id === this.originalScheduler.core_scenario.codename);
        } else {
          this.selectedScenario = this.scenarios[0];
        }
      }
    }

  },

  methods: {
    ...mapActions('resources', [
      'GET_SCHEDULERS',
    ]),
    
    copyToClipboard() {
      navigator.clipboard.writeText(this.resource.href);
      this.isURLCopied = true;
      setTimeout(() => {
        this.isURLCopied = false;
      }, 1000);
    },

    async setScheduler() {
      try {
        if (this.scheduler) {
          this.loadingScheduler = true;
          if (!this.originalScheduler) {
            const schedulerName = `Mise à jour ${this.scheduler.label} - file_download_${this.resource.id}`;
            const schedulerData = {
              codename: slugify(schedulerName),
              display_name: schedulerName,
              core_scenario_id: this.selectedScenario.id,
              scheduler: {
                class_id: this.scheduler.class_id,
                id: this.scheduler.id
              },
              kwargs: {
                dataset_id: this.currentDataset.id
              }
            };
            const scheduled = await fileDownloadAPI.setFileDownloadScheduler(
              this.resource.id,
              schedulerData
            );
            if (scheduled) {
              ErrorService.onSuccess(true, this.$t('resource.scheduler.setMessage'));
            }
          } else {
            const schedulerData = {
              core_scenario_id: this.selectedScenario.id,
              scheduler: {
                class_id: this.scheduler.class_id,
                id: this.scheduler.id
              },
              kwargs: {
                dataset_id: this.currentDataset.id
              }
            };
            const scheduled = await fileDownloadAPI.updateFileDownloadScheduler(
              this.resource.id,
              schedulerData,
              this.originalScheduler.id
            );
            if (scheduled) {
              ErrorService.onSuccess(true, this.$t('resource.scheduler.setMessage'));
            }
          }
          this.loadingScheduler = false;
          this.$emit('get');
        }
      } catch (err) {
        console.error(err);
        this.loadingScheduler = false;
      }
    },

    async removeScheduler() {
      try {
        this.loadingScheduler = true;
        const scheduled = await fileDownloadAPI.removeFileDownloadScheduler(this.resource.id, this.originalScheduler.id);
        if (scheduled) {
          ErrorService.onSuccess(true, this.$t('resource.scheduler.removeMessage'));
        }
        this.loadingScheduler = false;
        this.$emit('get');
      } catch (err) {
        console.error(err);
        this.loadingScheduler = false;
      }
    },

    modifyFileDownload() {
      this.$emit('modify', {
        type: this.isMain ? 'resource' : 'annexe',
        resourceType: 'file-download',
        data: {
          id: this.resource.id,
          ...this.newResource
        },
        resourceId: this.metaResource.id,
      });
    },
  }
};
</script>

<style lang="less" scoped>
.resource-form-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1em 1em;
  button.btn-outline-secondary {
    background-color: #F7F8FA;
    border: 2px solid #A9B2B9;
    border-radius: 8px;
    color: #2F3234;
  }
  button.btn-outline-secondary:not(.disabled) {
    color: white;
    background-color: #4b4b4b;
  }
}
i.bi-clipboard, i.bi-clipboard-check {
  color: #4b4b4b;
  font-weight: bold;
  font-size: 1.3rem;
  cursor: pointer;
  margin-left: 0.5rem;
}
</style>