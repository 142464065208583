var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-modal',{attrs:{"id":"update-file-upload-modal","size":"lg","title":"Mise à jour du fichier"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-left",attrs:{"variant":"outline-secondary"},on:{"click":_vm.cancel}},[_vm._v(" Annuler ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":function($event){return handleSubmit(_vm.updateFileUploadFile)}}},[_vm._v(" Confirmer ")])],1)]},proxy:true}],null,true),model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('div',{staticClass:"update-file-form"},[_c('div',{staticClass:"form-row form-row-50"},[_c('div',{staticClass:"form-group col"},[_c('label',{staticClass:"required",staticStyle:{"margin-top":"1em"}},[_vm._v(" "+_vm._s(_vm.$t('resource.format.label'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('resource.format.help'))+" ")]),_c('ValidationProvider',{attrs:{"id":"file-upload-format","name":"Format","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('Multiselect',{class:classes,attrs:{"options":_vm.allowedFormats ? _vm.allowedFormats : _vm.resourceDataFormats,"track-by":"codename","label":"display_name","select-label":"","selected-label":"","deselect-label":"","searchable":false,"placeholder":_vm.$t('resource.format.placeholder')},model:{value:(_vm.selectedFormat),callback:function ($$v) {_vm.selectedFormat=$$v},expression:"selectedFormat"}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)]),_c('ImportFile',{attrs:{"type":'ressource',"name":"main-resource","accept":_vm.selectedFormat ?
          _vm.resourceDataFormats
            .find(function (el) { return el.codename === _vm.selectedFormat.codename; }).extension
            .map(function (el) { return ("." + el) }).join(',') :
          '',"required":true},on:{"update":_vm.setResourceFile}}),(_vm.isGeo)?_c('div',{staticClass:"form-row",staticStyle:{"margin-top":"1.5rem"}},[_c('div',{staticClass:"form-group col-12"},[_c('label',{class:_vm.isFieldRequired('geographiclayer__display_name', _vm.formConfig.requiredFields) ? 'required' : ''},[_vm._v(" "+_vm._s(_vm.$t('resource.geographicalDisplayName'))+" ")]),_c('ValidationProvider',{ref:"geographical_display_label",attrs:{"rules":_vm.isFieldRequired('geographiclayer__display_name', _vm.formConfig.requiredFields),"name":_vm.$t('resource.geographicalDisplayName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var classes = ref.classes;
          var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.geoForm.geographiclayerDisplayName),expression:"geoForm.geographiclayerDisplayName"},{name:"sanitize",rawName:"v-sanitize"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.$t('resource.geographicalDisplayName')},domProps:{"value":(_vm.geoForm.geographiclayerDisplayName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.geoForm, "geographiclayerDisplayName", $event.target.value)}}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]):_vm._e(),(_vm.isXY)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{class:_vm.isFieldRequired('column_x', _vm.formConfig.requiredFields) ? 'required' : ''},[_vm._v(" "+_vm._s(_vm.$t('resource.XLon'))+" ")]),_c('ValidationProvider',{ref:"geographical_display_label",attrs:{"rules":_vm.isFieldRequired('column_x', _vm.formConfig.requiredFields),"name":_vm.$t('resource.XLon')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var classes = ref.classes;
          var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.geoForm.columnX),expression:"geoForm.columnX"},{name:"sanitize",rawName:"v-sanitize"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.$t('resource.XLon')},domProps:{"value":(_vm.geoForm.columnX)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.geoForm, "columnX", $event.target.value)}}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]):_vm._e(),(_vm.isXY)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{class:_vm.isFieldRequired('column_y', _vm.formConfig.requiredFields) ? 'required' : ''},[_vm._v(" "+_vm._s(_vm.$t('resource.YLon'))+" ")]),_c('ValidationProvider',{ref:"geographical_display_label",attrs:{"rules":_vm.isFieldRequired('column_y', _vm.formConfig.requiredFields),"name":_vm.$t('resource.YLon')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var classes = ref.classes;
          var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.geoForm.columnY),expression:"geoForm.columnY"},{name:"sanitize",rawName:"v-sanitize"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.$t('resource.YLon')},domProps:{"value":(_vm.geoForm.columnY)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.geoForm, "columnY", $event.target.value)}}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]):_vm._e(),(_vm.isXY)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{class:_vm.isFieldRequired('crs', _vm.formConfig.requiredFields) ? 'required' : ''},[_vm._v(" "+_vm._s(_vm.$t('resource.crs'))+" ")]),_c('ValidationProvider',{ref:"geographical_display_label",attrs:{"rules":_vm.isFieldRequired('crs', _vm.formConfig.requiredFields),"name":_vm.$t('resource.crs')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var classes = ref.classes;
          var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.geoForm.crs),expression:"geoForm.crs"},{name:"sanitize",rawName:"v-sanitize"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.$t('resource.crs')},domProps:{"value":(_vm.geoForm.crs)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.geoForm, "crs", $event.target.value)}}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }