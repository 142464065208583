var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dataset-right-sidebar"},[_c('DatasetCard',{attrs:{"dataset":_vm.currentDataset},on:{"set":function ($event) { return _vm.$emit('set-thumbnail', $event); }}}),_c('div',{staticClass:"side-buttons"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.push({ name: 'Datasets' })}}},[_c('i',{staticClass:"bi bi-box-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('b-button',{staticStyle:{"float":"right"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('save')}}},[_c('i',{staticClass:"bi bi-floppy-fill"}),_vm._v(" "+_vm._s(_vm.$t('datasetDetail.saveDataset'))+" ")])],1),_c('div',{staticClass:"sidebar-item",class:{ disabled: !_vm.currentDatasetPublishScenario || !_vm.currentDatasetUnpublishScenario }},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('datasetDetail.publication'))+" ")]),_c('SwitchButton',{staticStyle:{"margin-bottom":"2rem"},attrs:{"active":{
        label: 'Publié dans le catalogue',
        value: _vm.currentDatasetPublishScenario ? _vm.currentDatasetPublishScenario.id : null
      },"disabled":{
        label: 'Publication désactivée',
        value: _vm.currentDatasetUnpublishScenario ? _vm.currentDatasetUnpublishScenario.id : null
      },"initial-value":_vm.currentDatasetPublishState},on:{"toggle":_vm.runDatasetPublishScenario}})],1),_c('div',{staticClass:"sidebar-item"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('forms.datasets.organisation.label'))+" ")]),_c('SearchUsergroups',{attrs:{"types":['organisation', 'user-group'],"placeholder":_vm.$t('forms.datasets.organisation.help'),"usergroup":_vm.usergroup,"disabled":true,"reset-after":false,"limit":10},on:{"select":_vm.setUsergroup,"close":_vm.setUsergroup,"remove":_vm.setUsergroup},model:{value:(_vm.usergroup),callback:function ($$v) {_vm.usergroup=$$v},expression:"usergroup"}})],1),_c('div',{staticClass:"sidebar-item"},[_c('label',{staticStyle:{"margin-bottom":"0.5rem"}},[_vm._v(" "+_vm._s(_vm.$t('datasetDetail.recordType'))+" ")]),_c('b-overlay',{attrs:{"show":_vm.recordTypeBusy,"rounded":"lg","opacity":"0.6"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-spinner',{attrs:{"small":"","type":"grow","variant":"secondary"}}),_c('b-spinner',{attrs:{"type":"grow","variant":"dark"}}),_c('b-spinner',{attrs:{"small":"","type":"grow","variant":"secondary"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Please wait...")])],1)]},proxy:true}])},[_c('Multiselect',{attrs:{"options":_vm.datasetsRecordTypes,"track-by":"choice","label":"label","select-label":"","selected-label":"","deselect-label":"","searchable":false,"placeholder":_vm.$t('datasetDetail.recordType')},on:{"select":_vm.setRecordType},model:{value:(_vm.recordType),callback:function ($$v) {_vm.recordType=$$v},expression:"recordType"}})],1)],1),_c('div',{staticClass:"sidebar-item"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('datasetDetail.parentDataset.title'))+" ")]),_c('b-overlay',{attrs:{"show":_vm.parentLoading,"rounded":"lg","opacity":"0.6"}},[_c('div',{staticClass:"parent-dataset",class:{
          none: !_vm.parent
        }},[(!_vm.parent)?_c('span',[_vm._v(" Aucune fiche associée ")]):(_vm.parent && _vm.parent.display_name)?_c('div',{staticClass:"parent-dataset-display"},[_c('div',{staticClass:"parent-dataset-display-name"},[_vm._v(" "+_vm._s(_vm.parent.display_name)+" ")]),_c('i',{staticClass:"bi bi-trash-fill text-danger",on:{"click":_vm.removeParentDataset}})]):_vm._e()]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.isParentDatasetModalOpen = true}}},[_c('b-icon',{attrs:{"icon":"diagram-3","font-scale":"1.25"}}),_vm._v(" "+_vm._s(_vm.$t('datasetDetail.setParent').toUpperCase())+" ")],1)],1)],1),_c('div',{staticClass:"sidebar-item"},[_c('label',{staticStyle:{"margin-bottom":"0.5rem"}},[_vm._v(" "+_vm._s(_vm.$t('datasetDetail.permissions'))+" ")]),_c('b-overlay',{attrs:{"show":_vm.permissionsBusy,"rounded":"lg","opacity":"0.6"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-spinner',{attrs:{"small":"","type":"grow","variant":"secondary"}}),_c('b-spinner',{attrs:{"type":"grow","variant":"dark"}}),_c('b-spinner',{attrs:{"small":"","type":"grow","variant":"secondary"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Please wait...")])],1)]},proxy:true}])},[_c('Multiselect',{attrs:{"options":_vm.permissionLevels,"track-by":"code","label":"label","select-label":"","selected-label":"","deselect-label":"","searchable":false,"placeholder":_vm.$t('datasetDetail.permissions')},on:{"select":_vm.askForChangePermissionLevel},model:{value:(_vm.permissionLevel),callback:function ($$v) {_vm.permissionLevel=$$v},expression:"permissionLevel"}})],1)],1),_c('div',{staticClass:"sidebar-item"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.push({
        name: 'DatasetPermissions',
        params: {
          id: _vm.currentDataset.id,
        }
      })}}},[_c('img',{attrs:{"src":require("@/assets/icons/access_unlock_open.svg"),"alt":"Icon access unlock open"}}),_vm._v(" "+_vm._s(_vm.$t('datasetDetail.access'))+" ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.push({
        name: 'Logs',
        params: {
          object: 'dataset',
          id: _vm.currentDataset.id,
        }
      })}}},[_c('b-icon',{attrs:{"icon":"clock-history","font-scale":"1.25"}}),_vm._v(" "+_vm._s(_vm.$t('words.activityFlow').toUpperCase())+" ")],1),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.push({
        name: 'Tasks',
        query: {
          dataset: _vm.currentDataset.id
        }
      })}}},[_c('b-icon',{attrs:{"icon":"list-task","font-scale":"1.25"}}),_vm._v(" "+_vm._s(_vm.$t('words.tasksHistory').toUpperCase())+" ")],1),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$emit('duplicate')}}},[_c('b-icon',{attrs:{"icon":"files","font-scale":"1.25"}}),_vm._v(" "+_vm._s(_vm.$t('buttons.duplicate').toUpperCase())+" ")],1)],1),_c('ParentDatasetModal',_vm._g({attrs:{"open":_vm.isParentDatasetModalOpen,"usergroup":_vm.usergroup},on:{"hide":function($event){_vm.isParentDatasetModalOpen = false}}},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }