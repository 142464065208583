<template>
  <div
    class="back-link"
    @click="$router.push({ name: routeName })"
  >
    <b-icon-chevron-left font-scale="1.3" />
    <span>
      {{ title }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'BackLink',

  props: {
    routeName: {
      type: String,
      default: 'Home'
    },
    title: {
      type: String,
      default: ''
    }
  }
};
</script>