<template>
  <div class="annexe-form">
    <b-overlay
      id="overlay-background"
      :show="loading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <ResourceCreator
          ref="resourceCreator"
          :is-main="false"
          :required="required"
          @required="setRequired"
          @set="setForm"
        />
        <b-button
          variant="primary"
          @click="handleSubmit(startAddAnnexe)"
        >
          {{ $t('buttons.add') }}
        </b-button>
      </ValidationObserver>
    </b-overlay>
    <SelectScenario
      :open="selectingScenario"
      :context="'create'"
      :scenarios="possibleScenarios"
      @create="finishAddAnnexe"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ErrorService } from '@/services/error-service.js';

import ResourceCreator from '@/components/Resource/ResourceCreator.vue';
import SelectScenario from '@/components/Modals/SelectScenario.vue';

import { ValidationObserver } from 'vee-validate';

export default {
  name: 'AddAnnexe',

  components: {
    ResourceCreator,
    SelectScenario,
    ValidationObserver
  },

  props: {
    required: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      loading: false,
      form: {},
      selectingScenario: false,
      possibleScenarios: [],
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentDataset'
    ]),
    ...mapState('resources', [
      'currentResourceScenarios'
    ]),
  },

  mounted() {
    this.$refs.resourceCreator.setRequired(true);
  },

  methods: {
    ...mapActions('resources', [
      'START_CREATE_RESOURCE',
      'FINISH_CREATE_RESOURCE'
    ]),

    setRequired() {
      this.$refs.resourceCreator.setRequired(true);
    },

    setForm(e) {
      this.form = {
        ...this.form,
        ...e,
      };
    },

    startAddAnnexe() {
      this.loading = true;
      const postResourceData = {
        display_name: this.form.displayName,
        description: this.form.description,
        ...this.form.kind && this.form.kind.id && {
          kind_id: this.form.kind.id
        },
        last_update_date: this.form.lastUpdateDate,
        usergroup_id: this.currentDataset.usergroup.id,
      };
      const resourceAnnexeData = {
        ...this.form,
        usergroup_id: this.currentDataset.usergroup.id,
        dataset_id: this.currentDataset.id
      };
      if (this.form.type === 'file-upload') {
        resourceAnnexeData.dataformat_id = this.form.format.id;
        // this.createFileUploadAnnexe(resourceAnnexeData);
      } else if (this.form.type === 'ftp') {
        resourceAnnexeData.dataformat_id = this.form.format.id,
        resourceAnnexeData.href = this.form.selectedFtpFiles.map(el => el.value);
        // this.createFTPAnnexe(resourceAnnexeData);
      } else if (this.form.type === 'href') {
        resourceAnnexeData.dataformat_id = this.form.format.id;
        resourceAnnexeData.href = this.form.href;
        // this.createHrefAnnexe(resourceAnnexeData);
      } else if (this.form.type === 'file-download') {
        resourceAnnexeData.dataformat_id = this.form.format.id;
        resourceAnnexeData.href = this.form.href;
        // this.createFileDownloadAnnexe(resourceAnnexeData);
      }
      this.START_CREATE_RESOURCE({
        slug: this.form.type,
        postResourceData: postResourceData,
        resourceCreationData: resourceAnnexeData,
        datasetId: this.currentDataset.id,
        type: 2
      })
        .then(() => {
          if (this.currentResourceScenarios.create && this.currentResourceScenarios.create.length) {
            this.possibleScenarios = this.currentResourceScenarios.create;
            if (this.possibleScenarios.length > 1) {
              this.selectingScenario = true;
            } else {
              this.finishAddAnnexe(this.possibleScenarios[0].id);
            }
          } else {
            ErrorService.onError(null, this.$t('messages.error.scenario.create'));
            this.loading = false;
          }
        })
        .catch(() => {
          this.selectingScenario = false;
          this.loading = false;
        });
    },

    finishAddAnnexe(scenarioId) {
      this.selectingScenario = false;
      const finishResourceCreationData = {
        kwargs: {
          // resource__display_name: this.form.displayName,
          // resource__description: this.form.description,
          // resource__kind_id: this.form.kind && this.form.kind.id ? this.form.kind.id : null,
          // resource__last_update_date: this.form.lastUpdateDate,
          // usergroup_id: this.currentDataset.usergroup.id,
          dataset_id: this.currentDataset.id,
          target: {
            app_label: 'onegeo_dataset',
            model: 'dataset',
            pk: this.currentDataset.id
          },
          type: 2
        }
      };

      this.FINISH_CREATE_RESOURCE({
        typeResource: this.form.type,
        scenarioId: scenarioId,
        data: finishResourceCreationData,
      })
        .then(() => {
          this.$emit('close');
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>

.annexe-form {
  margin: 0.5em 0 1em 1em;
  // border: 1px solid #ced4da;
}

button {
  float: right;
  margin: 0.5em 0 1em 0;
  padding: 0.3em 2em 0.3em 2em;
  border: 2px solid #9BD0FF;
  border-radius: 8px;
}

</style>
