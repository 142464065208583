<!-- eslint-disable vue/multiline-html-element-content-newline -->
<template>
  <div>
    <b-overlay
      :show="loading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
    >
      <div
        :class="`alert alert-${alertType}`"
        role="alert"
      >
        <i :class="`bi bi-${processMessagIcon}`" />
        {{ $t(`datasetDetail.mainResource.messages.${processMessage}`) }}
        <router-link
          :to="{
            name: 'TaskDetail',
            params: { id: taskId }
          }"
          class="alert-link"
        >{{ $t('words.here') }}</router-link>.
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'ResourceProcessingAlert',

  props: {
    process: {
      type: Object,
      required: true
    },
    resourceId: {
      type: Number,
      required: true
    },
    type: {
      type: Number,
      default: 1
    },
    taskId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentDataset'
    ]),
    ...mapGetters('resources', [
      'isCurrentDatasetMainResourcePending',
      'isCurrentDatasetMainResourceFailed'
    ]),

    alertType() {
      if (this.process.state === 'PENDING') {
        return 'secondary';
      } else {
        return 'danger';
      }
    },
    processMessage() {
      if (this.process.state === 'PENDING') {
        return 'pending';
      } else {
        return 'failure';
      }
    },
    processMessagIcon() {
      if (this.process.state === 'PENDING') {
        return 'hourglass-split';
      } else {
        return 'exclamation-triangle-fill';
      }
    }
  },

  watch: {
    taskId(newValue, oldValue) {
      if (newValue && newValue !== oldValue && this.resourceId) {
        this.checkResourceProcessing(this.resourceId);
      }
    }
  },

  created() {
    if (this.resourceId) {
      this.checkResourceProcessing(this.resourceId);
    }
  },

  methods: {
    ...mapMutations([
      'SET_RELOAD_INTERVAL_ID',
      'CLEAR_RELOAD_INTERVAL_ID'
    ]),
    ...mapActions('resources', [
      'GET_CURRENT_DATASET_RESOURCES'
    ]),

    async checkResourceProcessing(id) {
      if (this.isCurrentDatasetMainResourcePending) {
        this.loading = true;
        await this.GET_CURRENT_DATASET_RESOURCES(this.currentDataset.id);
        this.loading = false;
        this.CLEAR_RELOAD_INTERVAL_ID(`resource_reloading-${id}`);
        this.SET_RELOAD_INTERVAL_ID(
          {
            name: `resource_reloading-${id}`,
            interval: setInterval(async () => {
              await this.checkResourceProcessing(id);
            },
            process.env.VUE_APP_RELOAD_INTERVAL)
          }
        );
      } else {
        this.CLEAR_RELOAD_INTERVAL_ID(`resource_reloading-${id}`);
      }
    },
  }
};
</script>
