<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-modal
      id="update-file-upload-modal"
      v-model="openModal"
      size="lg"
      title="Mise à jour du fichier"
    >
      <div class="update-file-form">
        <div class="form-row form-row-50">
          <div class="form-group col">
            <label 
              class="required"
              style="margin-top: 1em;"
            >
              {{ $t('resource.format.label') }}
            </label>
            <p>
              {{ $t('resource.format.help') }}
            </p>
            <ValidationProvider
              id="file-upload-format"
              v-slot="{ classes, errors }"
              name="Format"
              rules="required"
            >
              <div
                class="control"
                :class="classes"
              >
                <Multiselect
                  v-model="selectedFormat"
                  :options="allowedFormats ? allowedFormats : resourceDataFormats"
                  :class="classes"
                  track-by="codename"
                  label="display_name"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  :searchable="false"
                  :placeholder="$t('resource.format.placeholder')"
                />
                <span class="form-errors">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <ImportFile
          :type="'ressource'"
          name="main-resource"
          :accept="selectedFormat ?
            resourceDataFormats
              .find(el => el.codename === selectedFormat.codename).extension
              .map(el => { return `.${el}` }).join(',') :
            ''
          "
          :required="true"
          @update="setResourceFile"
        />

        <div
          v-if="isGeo"
          class="form-row"
          style="margin-top: 1.5rem;"
        >
          <div class="form-group col-12">
            <label :class="isFieldRequired('geographiclayer__display_name', formConfig.requiredFields) ? 'required' : ''">
              {{ $t('resource.geographicalDisplayName') }}
            </label>
            <ValidationProvider
              v-slot="{ classes, errors }"
              ref="geographical_display_label"
              :rules="isFieldRequired('geographiclayer__display_name', formConfig.requiredFields)"
              :name="$t('resource.geographicalDisplayName')"
            >
              <div
                class="control"
                :class="classes"
              >
                <input
                  v-model="geoForm.geographiclayerDisplayName"
                  v-sanitize
                  class="form-control"
                  type="text"
                  :placeholder="$t('resource.geographicalDisplayName')"
                >
                <span class="form-errors">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div
          v-if="isXY"
          class="form-row"
        >
          <div class="form-group col-12">
            <label :class="isFieldRequired('column_x', formConfig.requiredFields) ? 'required' : ''">
              {{ $t('resource.XLon') }}
            </label>
            <ValidationProvider
              v-slot="{ classes, errors }"
              ref="geographical_display_label"
              :rules="isFieldRequired('column_x', formConfig.requiredFields)"
              :name="$t('resource.XLon')"
            >
              <div
                class="control"
                :class="classes"
              >
                <input
                  v-model="geoForm.columnX"
                  v-sanitize
                  class="form-control"
                  type="text"
                  :placeholder="$t('resource.XLon')"
                >
                <span class="form-errors">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div
          v-if="isXY"
          class="form-row"
        >
          <div class="form-group col-12">
            <label :class="isFieldRequired('column_y', formConfig.requiredFields) ? 'required' : ''">
              {{ $t('resource.YLon') }}
            </label>
            <ValidationProvider
              v-slot="{ classes, errors }"
              ref="geographical_display_label"
              :rules="isFieldRequired('column_y', formConfig.requiredFields)"
              :name="$t('resource.YLon')"
            >
              <div
                class="control"
                :class="classes"
              >
                <input
                  v-model="geoForm.columnY"
                  v-sanitize
                  class="form-control"
                  type="text"
                  :placeholder="$t('resource.YLon')"
                >
                <span class="form-errors">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div
          v-if="isXY"
          class="form-row"
        >
          <div class="form-group col-12">
            <label :class="isFieldRequired('crs', formConfig.requiredFields) ? 'required' : ''">
              {{ $t('resource.crs') }}
            </label>
            <ValidationProvider
              v-slot="{ classes, errors }"
              ref="geographical_display_label"
              :rules="isFieldRequired('crs', formConfig.requiredFields)"
              :name="$t('resource.crs')"
            >
              <div
                class="control"
                :class="classes"
              >
                <input
                  v-model="geoForm.crs"
                  v-sanitize
                  class="form-control"
                  type="text"
                  :placeholder="$t('resource.crs')"
                >
                <span class="form-errors">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="outline-secondary"
            class="float-left"
            @click="cancel"
          >
            Annuler
          </b-button>
          <b-button
            variant="primary"
            class="float-right"
            @click="handleSubmit(updateFileUploadFile)"
          >
            Confirmer
          </b-button>
        </div>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex';

import Multiselect from 'vue-multiselect';
import ImportFile from '@/components/ImportFile.vue';

import { ValidationObserver,ValidationProvider, extend, configure } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

export default {
  name: 'UpdateFileUploadModal',

  components: {
    ImportFile,
    Multiselect,
    ValidationObserver,
    ValidationProvider
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },
    format: {
      type: Object,
      default: () => {
        return {};
      }
    },
    formConfig: {
      type: Object,
      default: () => {}
    },
  },

  data() {
    return {
      openModal: false,
      selectedFormat: null,
      file: null,
      isGeo: false,
      isXY: false,
      geoForm: {
        geographiclayerDisplayName: null,
        columnX: null,
        columnY: null,
        crs: null
      }
    };
  },

  computed: {
    ...mapState('resources', [
      'resourceDataFormats'
    ]),
    allowedFormats() {
      const type = this.resourceDataFormats.find(el => el.codename === this.format.codename).type;
      if (type && type.id) {
        return this.resourceDataFormats.filter(el => el.type.id === type.id);
      } else {
        return null;
      }
    }
  },

  watch: {
    open: function(newValue) {
      this.openModal = newValue;
    },
    selectedFormat: {
      deep: true,
      handler(newValue) {
        if (newValue && newValue.type.value === 'tabular-xy') {
          this.isGeo = true;
          this.isXY = true;
        } else if (newValue && newValue.type.value === 'geospatial') {
          this.isGeo = true;
          this.isXY = false;
        } else {
          this.isGeo = false;
          this.isXY = false;
        }
      }
    }
  },

  created() {
    if (this.format.type.value === 'tabular-xy') {
      this.isGeo = true;
      this.isXY = true;
    } else if (this.format.type.value === 'geospatial') {
      this.isGeo = true;
      this.isXY = false;
    } else {
      this.isGeo = false;
      this.isXY = false;
    }
  },

  methods: {
    cancel() {
      this.$emit('hide');
    },

    setResourceFile(e) {
      const formData = new FormData();
      formData.append('file', e);
      this.file = formData;
    },

    updateFileUploadFile() {
      this.$emit('update', {
        format: this.selectedFormat,
        file: this.file,
        ...this.isGeo && {
          geographicLayer: this.geoForm
        }
      });
      this.$emit('hide');
    }
  }
};
</script>

<style lang="less" scoped>
h2 {
  color: @blue;
  margin-top: 0.8em;
  margin-left: 0.5em;
}

.update-file-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  .form-row {
    width: 80%;
  }
  .form-row-50 {
    width: 50%;
    margin: auto;
  }
}

p {
  width: 100%;
  color: grey;
  font-style: italic;
  font-size: 0.7em;
  margin-bottom: 0.2em;
}

</style>
