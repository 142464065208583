<template>
  <div class="card-header">
    <div class="header-top">
      <div class="resource_icon">
        <i class="bi bi-file-earmark-arrow-up-fill" />
      </div>
      <div
        v-if="resourceDataFormats.length && resource.dataformat && resource.dataformat.id"
        class="resource_format"
      >
        {{
          resourceDataFormats.length && resource.dataformat && resource.dataformat.id ?
            resourceDataFormats.find(el => el.id === resource.dataformat.id).codename :
            ''
        }}
      </div>
      <div class="resource_name">
        {{
          resource.file && resource.file.href ?
            resource.file.href.split('/').slice(-1)[0] :
            ''
        }} 
        <i v-if="resource && resource.file">({{ fileSize }})</i>
      </div>
    </div>
    <div class="header-bottom">
      <slot name="publication" />
      <div
        class="form-group"
        style="display: flex; justify-content: flex-end;"
      >
        <b-button
          :id="uploadId"
          variant="outline-secondary"
          :style="'margin-right: 1rem;'"
          @click="isUpdateResourceFileModalOpen = true"
        >
          <b-icon-upload />
        </b-button>
        <b-popover
          :target="uploadId"
          placement="topleft"
          triggers="hover"
          title="Mettre à jour"
          content="Importer un nouveau fichier"
        />
        <b-button
          v-if="fileUrl"
          :id="downloadId"
          variant="outline-primary"
          :href="fileUrl"
        >
          <b-icon-download />
        </b-button>
        <b-popover
          v-if="resource.file && resource.file.href"
          :target="downloadId"
          placement="topright"
          triggers="hover"
          title="Télécharger"
          :content="`${resource.file.href.split('/').slice(-1)[0]}`"
        />
      </div>
    </div>
    <UpdateFileUploadModal
      :open="isUpdateResourceFileModalOpen"
      :format="
        resourceDataFormats.find(el => resource.dataformat && resource.dataformat.id ? el.id === resource.dataformat.id : 1)
      "
      :form-config="$config.forms.resource.fileUpload"
      @hide="isUpdateResourceFileModalOpen = false"
      @update="modifyFileUploadFile"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { fileConvertSize } from '@/utils';

import UpdateFileUploadModal from '@/components/Resource/modals/UpdateFileUploadModal.vue'; 

export default {
  name: 'FileUploadEditor',

  components: {
    UpdateFileUploadModal
  },

  props: {
    isMain: {
      type: Boolean,
      default: true
    },
    resource: {
      type: Object,
      default: () => {}
    },
    metaResource: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      isUpdateResourceFileModalOpen: false
    };
  },

  computed: {
    ...mapState('resources', [
      'resourceDataFormats',
    ]),

    fileSize() {
      if (this.resource.file) {
        return fileConvertSize(this.resource.file.size);
      }
      return '0';
    },

    uploadId() {
      return `upload-button-${this.resource.id}`;
    },

    downloadId() {
      return `download-button-${this.resource.id}`;
    },

    fileUrl() {
      if (this.resource.file && this.resource.file.href) {
        return new URL(this.resource.file.href, process.env.VUE_APP_DOMAIN).href;
      }
      return null;
    },
  },

  mounted() {
    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.isUpdateResourceFileModalOpen = false;
    });
  },

  methods: {
    modifyFileUploadFile(e) {
      this.$emit('modify', {
        type: this.isMain ? 'resource' : 'annexe',
        resourceType: 'file-upload',
        data: {
          location: this.resource['@uri'],
          format: e.format,
          file: e.file,
        },
        resourceId: this.metaResource.id,
        ...e.geographicLayer && {
          geographicLayer: e.geographicLayer
        }
      });
    },
  }
};
</script>